* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .flex-button {
    padding: 10px 20px;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background-color: #664712;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .flex-button.achieved {
    background-color: #28a745;
  }
  
  .flex-button.donate-btn {
    background-color: #664712;
  }
  
  .flex-button:hover {
    box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  }
  
  @media screen and (max-width: 930px) {
    #butdiv {
      flex-direction: row;
    }
  }