@font-face {
  font-family: "Biysk-Regular";
  src: local("Biysk-Regular"),
    url("./fonts/Biysk\ Regular.ttf") format("truetype");
}
@import url(http://fonts.googleapis.com/css?family=Lato:100,300,400,700);
@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,400,300,600,700);
@import url(http://fonts.googleapis.com/css?family=Noto+Sans:400,700);
@font-face {
  font-family: "cuyabra";
  src: local("cuyabra"), url("./fonts/cuyabra.otf") format("truetype");
}
body {
  overflow-x: hidden !important;
}
.containers {
  overflow-x: hidden !important;
}

.wrapper {
  padding: 5px;
  max-width: 100%;
  width: 100%;
}
.columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 10px 70px;
}
.column {
  flex: 1;
  margin: 2px;
  padding: 10px;
}
.column:first-child {
  margin-left: 0;
}
.column:last-child {
  margin-right: 0;
}
@media screen and (max-width: 980px) {
  .columns .column {
    margin-bottom: 5px;
    flex-basis: 40%;
  }
  .columns .column:nth-last-child(2) {
    margin-right: 0;
  }
  .columns .column:last-child {
    flex-basis: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 680px) {
  .columns .column {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
}

.border-container {
  padding: 2%;
}
.header-btn:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
}
.loading-img {
  margin: 10%;
  width: 5%;
}
.drop-lang {
  position: absolute;
  right: 0;
  top: -1%;
}
.header-btn {
  background-color: rgb(102, 71, 18);
  border: none;
  color: white;
  padding: 2px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 1px 160px 1px -274px;
  cursor: pointer;
  font-size: 13px;
  border-radius: 16px;
  font-weight: bold;
  font-family: "cuyabra";
}
.h1-text-h {
  text-align: center;
  font-weight: bold;
  color: #686868;
  font-size: 33px;
  font-family: "cuyabra";
}
.h2-text-h {
  font-size: 22px;
  color: #686868;
  font-family: "cuyabra";
  text-align: left;
}
.h6-text-h {
  padding-bottom: 10px;
  font-family: "cuyabra";
  font-weight: 600;
  font-size: 12px;
  text-align: left;
}
.h6-text2-h {
  font-family: "cuyabra";
  font-weight: 600;
  font-size: 12px;
  text-align: left;
}
.h6-text1-h {
  font-family: "cuyabra";
  font-weight: 300;
  font-size: 12px;
  text-align: left;
}
.footer-side .footer-text:hover {
  color: #fff;
  text-decoration: none;
}
.footer-side {
  text-align: left;
  margin-left: 5%;
}
.footer-center {
  margin-left: -15%;
}
.footer-text {
  text-decoration: none;
  color: white;
  font-family: "cuyabra";
  text-align: left;
  font-size: 12px;
}
.footer-copy-text {
  text-decoration: none;
  color: white;
  font-family: "cuyabra";
  font-size: 12px;
  text-align: center;
}
.footer-center-text {
  text-decoration: none;
  color: white;
  font-family: "cuyabra";
  font-size: 12px;
  text-align: center;
  padding-top: -50px;
}
.footer-blink {
  color: #ffd9a2;
  font-size: 18;
}
.footer-right {
  text-align: right;
  margin-right: -8%;
}
footer {
  background-color: #664712;
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: contain;
  height: auto;
  width: 100%;
  color: #fff;
}
.text-bottom {
  padding-bottom: 4%;
}
.middle {
  position: absolute;
  font-family: "clip";
  top: 92%;
  left: 40%;
  text-align: center;
}
.background-text {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0px 0px 37px 37px;
  width: 101.5%;
  margin-left: -1%;
  padding-bottom: 1%;
}
.footer-socials {
  text-decoration: none;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1rem 0;
}
.footer-socials li {
  margin: 0 10px;
}
.footer-socials a {
  text-decoration: none;
  color: #fff;
}
.footer-socials a i {
  font-size: 1.1rem;
}
.footer-socials .facebook a:hover {
  color: #4267b2;
}
.footer-socials .dribbble a:hover {
  color: #bc2a8d;
}
.footer-socials .gplus a:hover {
  color: red;
}
.footer-socials .twitter a:hover {
  color: #1da1f2;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  display: none;
  color: rgb(167, 60, 60);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .img-card:hover{
  transform: scale(0.9, 0.9);
  transition: 0.25s;
  } */
.img-card {
  /* min-width: none; */
  display: flex;
  align-items: center;
  /* padding-top: 4px; */
  flex-direction: column;
}
.responsive {
  width: 25.1vw;
  height: 40vh;
  display: block;
  margin-left: 0px;
}
.responsive1 {
  width: 32vw;
  height: 58vh;
  display: block;
  margin-left: -11px;
}

.slick-prev {
  color: rgb(26, 94, 221);
  font-size: 10px;
}

.slick-next {
  color: rgb(26, 94, 221);
  font-size: 10px;
}

.slick-card-body {
  background-color: white;
  width: 89%;
  height: 100px;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
}
.slick-btn {
  background-color: rgb(102, 71, 18);
  color: white;
  font-size: 11px;
  text-align: center;
  padding: 5px 10px;
  border: none;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 2%;
}
.slick-card-text a:hover {
  text-decoration: none;
  color: white;
}

.slick-card-title {
  font-size: 70%;

  text-align: center;
  font-family: "cuyabra";
}
.slick-card-text {
  font-size: 70%;

  text-align: center;
  font-family: "cuyabra";
}
.slick-card-btn {
  background-color: rgb(102, 71, 18);
  color: white;
  font-size: 10px;
  text-align: center;
  padding: 5px 10px;
  border: none;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 2%;
}
#Reg {
  overflow: hidden;
  z-index: 2;
  position: relative;
  width: 100px;
  height: 36px;

  text-transform: uppercase;
  font-size: 15px;
  cursor: pointer;
}
#Reg2 {
  content: "";
  z-index: 2;

  border: 2px solid rgb(102, 71, 18);
  border-radius: 30px;
}
@media screen and (min-width: 200px) and (max-width: 540px) {
  .col-lg-6 .btn-primary {
    font-size: 8px;
  }

  .col-lg-3 #groupimage {
    margin-top: -40px;
  }

  img .card-img1 {
    height: 30px;
    width: 30px;
  }
  img .card-img2 {
    height: 30px;
    width: 30px;
  }
  .k-row #user_captcha_input {
    font-size: 10px;
    color: black;
  }
  .k-row #captcha {
    font-size: 7px;
  }
  /* #Reg{
           font-size: 10px;
         } */
  #loginbuttontamil {
    font-size: 10px;
  }

  .header-btn {
    display: none;
  }
  .col-lg-3 img .card-img {
    margin-top: -200px;
    height: 20px;
    width: 20px;
  }
  .responsive-table {
    width: 60%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    border-spacing: -10px;
    font-size: 5px;
  }
  #btnSubmit {
    width: 25px;
    height: 10px;
    font-size: 4px;
  }

  .padding-top .container .row1 {
    margin-right: -118px;
    margin-left: 97px;
    width: 100%;
  }

  .row1 .cont #login {
    width: 100%;
  }
  /* .row1 .cont #Reg{
      width: 150px;
  } */
  .sub-cont {
    display: none;
  }

  .col-lg-6 .btn-primary {
    margin-top: -26px;
  }

  #linkid .forgotpass {
    font-size: 10px;
  }

  .drop-lang {
    position: absolute;
    right: 20%;
    top: 2%;
  }

  .footer-right {
    margin-right: 0%;
  }
  .footer-side {
    margin-left: 0%;
  }
  .footer-center {
    margin-left: 0%;
  }
}
.section {
  padding: 2%;
}
.bgp {
  background-image: url(../assets/images/10.jpg);
}
.header-bottom {
  background-image: url(../assets/images/10.jpg);
  padding: 5px 0;
  position: sticky;
  top: 0;
  z-index: +1;
}
.logo-text {
  color: blue;
  font-family: "Biysk-Regular";
  font-size: 30px;
}
.span-logo-text {
  padding-top: 10px;
}
.logo {
  width: 30%;
}
.navbar {
  display: flex;
  align-items: center;
  height: 75px;
  margin-left: -3%;
  justify-content: space-evenly;
}
.nav-links {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  width: 75%;
}
.nav-links-mobile {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  width: 75%;
}
.navbar a {
  text-decoration: none;
}
.navbar li:hover {
  color: #00aeef;
}
.ourstory {
  color: #000;
  font-size: 16px;
  padding: 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "cuyabra";
}
.mobile-menu-icon {
  display: none;
}

/* Media Query Responsive */
@media screen and (min-width: 540px) {
  #login #tnr {
    margin-left: 78px;
  }
}
@media screen and (max-width: 280px) {
  #tnr {
    margin-left: -50px;
    font-size: 8px;
  }
  .tnr1 {
    font-size: 8px;
  }
}
@media screen and (max-width: 1280px) {
  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    text-align: left;
    list-style: none;
    background-image: url(../assets/images/10.jpg);
    left: 0;
    top: 75px;
    transition: all 0.5s ease-out;
    width: 100%;
    z-index: +1;
  }
  .Privacy,
  .Faq {
    color: #fff;
    text-align: center;
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease;
  }
  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    color: none;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
    border: 2px solid #00aeef;
    background-image: url(../assets/images/10.jpg);
  }
  .fa-bars:before {
    content: "\f0c9";
    color: #00aeef;
  }
  .required {
    color: red;
  }
}
@media screen and (min-width: 580px) and (max-width: 1270px) {
  .responsive-table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    border-spacing: -10px;
    font-size: 8px;
  }
  #btnSubmit {
    width: 40px;
    height: 20px;
    font-size: 7px;
  }

  .required {
    color: red;
  }
}
@media screen and (min-width: 900px) {
  #tnr1 {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .tnr1 .img__btn {
    display: none;
  }
  #required80G1 {
    display: none;
  }
}
@media screen and (min-width: 1280px) {
  .sign-in .col-md-8 {
    width: 95.666667%;
  }
  .required {
    color: red;
  }
  .tnr1 .img__btn {
    display: none;
  }
  #required80G1 {
    display: none;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 30%;
  height: auto;
  top: 50%;
  left: 84%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block !important;
}

.display-none {
  display: none;
}

.modal-header .closes {
  height: 34px;
  width: 34px;
  float: right;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: rgb(19, 17, 17);
}
.modal-header .closes:hover {
  color: #fa3f6f;
}
.App {
  text-align: left;
}
.hovercolr:hover {
  background-color: #459489;
}

.btn.circular {
  border-radius: 50em !important;
}
.red {
  background-color: #e74c3c;
  color: #fbdedb;
}
.btn {
  padding: 1em 2.1em 1.1em;
  border-radius: 3px;
  margin: 8px 8px 8px 8px;
  color: #fbdedb;
  display: inline-block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-weight: 800;
  font-size: 0.85em;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  position: relative;
}
.btn :hover,
.btn :focus {
  opacity: 0.8;
}
.btn :active {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  color: #fbdedb;
}
.green {
  background-color: #5bbd72;
}
.text-field-outlined {
  border-radius: 5px;
  border: 1px solid #45b39d;
  width: 166px;
  height: 38px;
  position: relative;
  transition: 150ms ease;
}
.text-field-outlined:focus-within {
  border-width: 2px;
  border-color: #1cd4bc;
}
.text-field-outlined > input {
  outline: none;
  padding: 20px 10px 20px 15px;
  background: none;
  position: relative;
  border: none;
  width: 100%;
}
#table {
  border-collapse: collapse;
}

.table-rows {
  transition: all 0.3s ease;
  cursor: pointer;
  text-align: left;
}

.items {
  padding: 15px 35px;
  font-size: 15px;
}

.table-rows:nth-child(even) {
  background-color: rgba(95, 197, 208, 0.21);
}

.table-rows:nth-child(odd) {
  background-color: rgba(55, 131, 138, 0.17);
}

.table-rows:hover {
  background-color: rgb(51, 204, 166);
  transform: scale(1.1);
}

.table-rows:hover svg {
  opacity: 1;
  transform: scale(1.2);
}
.coin {
  width: 12%;
  margin-left: 5px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dot {
  transition: all 500ms ease;
  background: #4a72da;
  box-shadow: 1px 2px 3px #999;
  height: 5px;
  width: 5px;
  display: inline-block;
  border-radius: 10px;
  animation: wave 2s ease infinite;
}

.dot:nth-child(1) {
  animation-delay: 0;
}
.dot:nth-child(2) {
  animation-delay: 100ms;
}
.dot:nth-child(3) {
  animation-delay: 200ms;
}
.dot:nth-child(4) {
  animation-delay: 300ms;
}

@keyframes wave {
  0%,
  40%,
  100% {
    transform: translate(0, 0);
    background-color: #4a72da;
  }
  10% {
    transform: translate(0, -15px);
    background-color: red;
  }
}

.responsive1 {
  width: 48vw;
  height: 58vh;
  display: block;
  margin-left: -11px;
}
@media screen and (min-width: 92px) and (max-width: 770px) {
  .responsive {
    width: 76.5vw;
    height: 28vh;
  }
}
@media screen and (min-width: 770px) and (max-width: 800px) {
  .responsive {
    width: 23.5vw;
    height: 28vh;
  }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
  .responsive {
    width: 24vw;
    height: 28vh;
  }
}
@media screen and (min-width: 901px) and (max-width: 1200px) {
  .responsive {
    width: 24.4vw;
    height: 28vh;
    margin-left: 0px;
  }
}

@media (min-width: 768px) {
  #containerid {
    width: 98%;
  }
  #containerid1 {
    width: 95%;
  }
  #containerid .navbar-right #tamilbtn1 {
    margin-right: -2px;
  }
}
@media screen and (min-width: 1190px) and (max-width: 1443px) {
  #notifysidebar .recent {
    font-size: 10px;
  }
}
@media screen and (min-width: 842px) and (max-width: 1183px) {
  #notifysidebar .sidebar-item {
    margin-left: 69%;
  }
}

/*  */
.inv-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 87%;
  width: 10%;
  position: fixed;
  margin-top: 10%;
  white-space: nowrap;
}
.inv-button1 {
  width: 100%;
  background-color: rgb(102, 71, 18);
  margin-top: 5%;
  margin-right: -100px;
  border-radius: 15px;
  border: 2px solid #63501e94;
  cursor: pointer;
}
.inv-text {
  text-align: center;
  text-decoration: none;
  font-size: 0.9vw;
  margin-top: 2%;
  margin-bottom: 3.5%;
  white-space: normal;
}
.inv-text-link {
  text-decoration: none;
  color: white;
}
.arrow-btn {
  width: 100%;
  font-size: 2px;
  cursor: pointer;
}
.backtotop-btn {
  cursor: pointer;
}
.profile-img {
  width: 10vw;
  border-radius: 200px;
  height: 40vh;
}
.circle-containe {
  width: 100%;
}
.nav-extra {
  width: 100%;
  background-color: #e74c3c;
}
/* .circle-img{
  width: 25vw;
border-radius: 200px;
height: 40vh;
} */

@media only screen and (max-width: 767px) {
  .inv-button1 {
    display: none;
  }
  .arrow-btn {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .newedit-btn {
    display: none;
  }
  .new__btn2 {
    display: none;
  }
  .circle-containe {
    width: 25vw;
    margin: 0% 40%;
  }
}
.circle-img {
  display: block;
  width: 300px;
  height: 300px;
  overflow: hidden;
  -webkit-border-radius: 300px;
  -moz-border-radius: 300px;
  -ms-border-radius: 300px;
  -o-border-radius: 300px;
  border-radius: 300px;
  position: relative;
  margin: 0px auto;
  text-align: center;
  margin-bottom: 10px;
}
.small-circle-image {
  width: 50px;
  height: 50px;
  overflow: hidden;
  -webkit-border-radius: 300px;
  -moz-border-radius: 300px;
  -ms-border-radius: 300px;
  -o-border-radius: 300px;
  border-radius: 300px;
  position: absolute;
  left: 40%;
  margin: 0px auto;
  text-align: center;
}

.dropdown-container {
  margin: 0% 2%;
}
.drodown-box {
  width: 90%;
  /* height: 120px; */
}

.slight-grid {
  width: 102%;
}

@media only screen and (max-width: 1199px) {
  .circle-img {
    display: block;
    width: 200px;
    height: 200px;
    overflow: hidden;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
    -ms-border-radius: 200px;
    -o-border-radius: 200px;
    border-radius: 200px;
    position: relative;
    margin: 0px auto;
    text-align: center;
    margin-bottom: 10px;
  }
  .preview-circle-image {
    display: block;
    width: 200px;
    height: 200px;
    overflow: hidden;
    -webkit-border-radius: 300px;
    -moz-border-radius: 300px;
    -ms-border-radius: 300px;
    -o-border-radius: 300px;
    border-radius: 300px;
    position: absolute;
    top: 10%;
    left: 14%;
    margin: 0px auto;
    text-align: center;
    margin-bottom: 10px;
  }
}

/* Navbar responsive */

.englishbtn {
  position: relative;
  left: -90%;
}
.hindibtn {
  position: relative;
  left: -70%;
}
.olangbtn {
  position: relative;
  left: -20%;
}
.tamilbtn {
  font-size: 2vh;
}
.englishspace {
  margin-left: 50%;
  margin-right: 4.5%;
}
.tamilspace {
  margin-left: 13%;
  margin-right: 0%;
}
.hindispace {
  margin-left: 50%;
}
.otherspace {
  margin-left: 30%;
  margin-right: 10%;
}
.new_data {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .new_data {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 75%;
    z-index: 1;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .englishbtn {
    position: relative;
    left: 0;
    font-size: 550px;
  }
  .hindibtn {
    position: relative;
    left: 0;
    font-size: 0.5vh;
  }
  .olangbtn {
    position: relative;
    left: 0;
    font-size: 0.5vh;
  }
  .englishspace {
    margin-left: 35%;
    margin-right: 5%;
  }
  .tamilspace {
    margin-left: 20%;
    margin-right: 0%;
  }
}

@media only screen and (max-width: 908px) {
  .new_data {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 100%;
    z-index: 1;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .new_data {
    width: 100%;
    position: none;
  }
  .englishspace {
    margin-left: 25%;
    margin-right: 5%;
  }
  .tamilspace {
    margin-left: 20%;
    margin-right: 0%;
  }
}
/* .multiimages{
  width: 29.5vw;
} */

.navbar-new-text {
  color: blue;
  font-family: Biysk-Regular;
  margin-top: 4%;
  font-size: 2.3vw;
}

.new-design:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  color: #fff;
}
.new-design {
  position: absolute;
  left: 35%;
  top: 23%;
  color: #fff;
  border-radius: 15px;
  padding: 1% 2%;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  background-color: rgb(102, 71, 18);
}
.parop-boxx {
  display: flex;
  justify-content: end;
  color: #fff;
  flex-direction: row;
}
.parop-boxx a {
  color: #000;
  text-decoration: none;
}
.parop-home-boxx a {
  color: #000;
  text-decoration: none;
}
.parop-home-boxx1 a {
  color: #000;
  text-decoration: none;
}
.parop-boxx .text-parop-menu {
  color: #1da1f2;
}
.parop-home-boxx .text-parop-menu {
  color: #1da1f2;
}
.parop-home-boxx1 .text-parop-menu {
  color: #1da1f2;
}

.parop-kannada-boxx .text-parop-menu {
  color: #1da1f2;
}

.parop-tamil-boxx .text-parop-menu {
  color: #1da1f2;
}
.parop-tamil-boxx1 .text-parop-menu {
  color: #1da1f2;
}

.parop-menu {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0% 0% 0% 8%;
}
.parop-menu1 {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0% 0% 0% 7%;
}
.new-hindi-design:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  color: #fff;
}
.new-hindi-design {
  position: absolute;
  left: 43%;
  top: 9%;
  color: #fff;
  border-radius: 15px;
  padding: 1%;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  background-color: rgb(102, 71, 18);
}
.parop-hindi-menu {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.15vw;
  margin: 0% 0% 0% 8%;
}

.new-kannada-design:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  color: #fff;
}
.new-kannada-design {
  position: absolute;
  left: 43%;
  color: #fff;
  border-radius: 20px;
  padding: 1%;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1vw;
  background-color: rgb(102, 71, 18);
}
.parop-kannada-boxx {
  display: flex;
  justify-content: end;
  color: #fff;
  flex-direction: row;
}
.parop-kannada-boxx a {
  color: #000;
}
.parop-kannada-menu {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1vw;
  margin-left: 4%;
}

.parop-kannada-menu1 {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1vw;
  margin-left: 3%;
}
.new-tamil-design:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  color: #fff;
}
.new-tamil-design1:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  color: #fff;
}
.new-tamil-design {
  position: absolute;
  left: 19%;
  color: #fff;
  border-radius: 20px;
  padding: 1%;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1vw;
  background-color: rgb(102, 71, 18);
}
.new-tamil-design1 {
  position: absolute;
  left: 29%;
  color: #fff;
  border-radius: 20px;
  padding: 1%;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1vw;
  background-color: rgb(102, 71, 18);
}

.new-telugu-design:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  color: #fff;
}
.new-telugu-design1:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  color: #fff;
}
.new-telugu-design {
  position: absolute;
  left: 19%;
  color: #fff;
  border-radius: 20px;
  padding: 1%;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1vw;
  background-color: rgb(102, 71, 18);
}
.parop-tamil-boxx {
  width: 200%;
  margin-left: -60%;
}
.parop-tamil-boxx1 {
  width: 200%;
  margin-left: -30%;
}

.parop-tamil-boxx1 a {
  color: #000;
}
.parop-tamil-boxx a {
  color: #000;
}
.parop-tamil-menu {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1vw;
  margin-left: 1.8%;
}
.new-telugu-design:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  color: #fff;
}
.new-telugu-design {
  position: absolute;
  left: 35%;
  top: 23%;
  color: #fff;
  border-radius: 15px;
  padding: 1%;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1vw;
  background-color: rgb(102, 71, 18);
}
.parop-telugu-menu {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1vw;
  margin: 0% 0% 0% 8%;
}
.parop-telugu-menu1 {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1vw;
  margin: 0% -4% 0% 5%;
}
.ebtn {
  font-size: 16px;
}
.mag {
  margin: 2% 2%;
}
.pro-nav {
  height: 50px;
  width: 98%;
  background-color: transparent;
  position: relative;
}

.pro-nav > .pro-nav-header {
  display: inline;
}

.pro-nav > .pro-nav-header > .pro-nav-title {
  display: inline-block;
  font-size: 22px;
  color: #000;
  padding: 0px 10px 10px 10px;
}

.pro-nav > .pro-nav-btn {
  display: none;
}

.pro-nav > .pro-nav-links {
  display: inline;
  float: right;
  font-size: 18px;
  width: 40%;
  margin-top: 1.5%;
}
.pro-nav > .pro-nav-links1 {
  display: inline;
  float: right;
  font-size: 18px;
  width: 43%;
}
.pro-nav > .pro-nav-links2 {
  display: inline;
  float: right;
  font-size: 18px;
  width: 46%;
}

.pro-nav > .pro-nav-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #000;
}
.pro-nav > .pro-nav-links1 > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #000;
}
.pro-nav > .pro-nav-links2 > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #000;
}
.pro-nav > #pro-nav-check {
  display: none;
}

.pro-nav-comes {
  margin-top: 50%;
}

/* @media (max-width: 1200px) {
  .HeaderMenualignment {
    width: 100%;
    text-align: center;
    justify-content: center;
    padding-left: 25%;
    padding-right: 25%;
  }
} */

@media (max-width: 1100px) {
  .causefor-container {
    justify-content: center;
    width: 100%;
    padding: 0% 20%;
  }
  .causefor-container2 {
    justify-content: center;
  }
  .new-padding {
    padding: 14% 3%;
  }
  .navbar-new-text {
    color: blue;
    font-family: Biysk-Regular;
    margin-top: -1%;
    font-size: 30px;
  }
  .pro-center{
    position:relative;
      top:-1%;
      left:1%;
      width: 50%;
    }
    
    .pro-center-tamil{
      position:relative;
        top:1%;
        left:0%;
        width: 50%;
      }
      
  .new-design:hover {
    box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
    color: #fff;
  }
  .new-design {
    position: relative;
    top: 21px;
    left: 1%;
    color: #fff;
    text-transform: uppercase;
    background-color: rgb(102, 71, 18);
    font-size: 14px;
    padding: 5%;
    white-space: nowrap;
    padding-left: 9%;
    padding-right: 7%;
  }
  .parop-boxx {
    display: flex;
    justify-content: end;
    color: #fff;
    flex-direction: column;
  }
  .parop-menu {
    font-family: cuyabra;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    margin: 4% 0% 0% 2%;
  }
  .parop-menu1 {
    font-family: cuyabra;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    margin: 4% 0% 0% 2%;
  }
  /* .pro-home-nav-links, .parop-boxx-top
  {
    margin-top: 4%;
  } */
  .parop-boxx-top {
    margin-top: 4%;
  }
  .new-hindi-design:hover {
    box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
    color: #fff;
  }
  .new-hindi-design {
    position: relative;
    top: 14px;
    left: 0%;
    color: #fff;
    text-transform: uppercase;
    background-color: rgb(102, 71, 18);
    font-size: 14px;
    padding: 3%;
    white-space: nowrap;
  }
  .parop-hindi-menu {
    font-family: cuyabra;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    margin: 3% 0% 0% 2%;
  }

  .parop-kannada-boxx {
    display: flex;
    flex-direction: column;
    margin-left: 0%;
    margin-top: 0%;
  }
  .new-kannada-design:hover {
    box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
    color: #fff;
  }
  .new-kannada-design {
    position: relative;
    top: 14px;
    left: 0%;
    color: #fff;
    text-transform: uppercase;
    background-color: rgb(102, 71, 18);
    font-size: 14px;
    padding: 3%;
    white-space: nowrap;
  }
  .parop-kannada-menu {
    font-family: cuyabra;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    margin: 3% 0% 0% 0%;
  }
  .new-telugu-design:hover {
    box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
    color: #fff;
  }

  .new-tamil-design:hover {
    box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
    color: #fff;
  }
  .new-tamil-design1:hover {
    box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
    color: #fff;
  }
  .new-tamil-design {
    position: relative;
    top: 14px;
    left: 0%;
    color: #fff;
    text-transform: uppercase;
    background-color: rgb(102, 71, 18);
    font-size: 14px;
    padding: 3%;
    white-space: nowrap;
  }

  /* .new-telugu-design{
    position: relative;
    top:14px;
    left: 4%;
    color: #fff;
    text-transform: uppercase;
    background-color: rgb(102, 71, 18);
    font-size:14px;
    padding: 3%;
    white-space: nowrap;

  } */
  .new-tamil-design1 {
    position: relative;
    top: 21px;
    left: 0%;
    color: #fff;
    text-transform: uppercase;
    background-color: rgb(102, 71, 18);
    font-size: 14px;
    padding: 3%;
    white-space: nowrap;
  }
  .parop-tamil-boxx {
    display: flex;
    flex-direction: column;
    margin-left: 0%;
    margin-top: 0%;
  }
  .parop-tamil-boxx1 {
    display: flex;
    flex-direction: column;
    margin-left: 0%;
    margin-top: 0%;
  }
  .parop-tamil-menu {
    font-family: cuyabra;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
    margin: 2% 0% 0% 0%;
  }
  .new-telugu-design:hover {
    box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
    color: #fff;
  }
  .new-telugu-design {
    position: relative;
    top:14px;
    left: 7%;
    color: #fff;
    text-transform: uppercase;
    background-color: rgb(102, 71, 18);
    font-size: 14px;
    padding: 3%;
    white-space: nowrap;
  }
  .parop-telugu-menu {
    font-family: cuyabra;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    margin: 3% 0% 0% 2%;
  }
  .parop-telugu-menu1 {
    font-family: cuyabra;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    margin: 3% 0% 0% 2%;
  }

  .pro-nav > .pro-nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .pro-nav > .pro-nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .pro-nav > .pro-nav-btn > label > .pro-naav > span {
    display: block;
    width: 25px;
    height: 8px;
    border-top: 3px solid #fff;
    cursor: pointer;
  }

  /*  */

  .pro-nav > .pro-nav-btn > label > .pro-naav:hover {
    background-color: #1da1f2;
  }
  .pro-nav > .pro-nav-btn > label > .pro-naav {
    border: 1px solid #00aeef;
    width: 40px;
    height: 37px;
    padding: 30%;
    border-radius: 5px;
    cursor: pointer;
  }

  .pro-nav > .pro-nav-links {
    position: absolute;
    display: block;
    width: 103%;
    /* background-image: url(../assets/images/10.jpg); */
    height: 0px;
    transition: all 0.2ms ease-in;
    overflow-y: hidden;
    top: 80px;
    left: 10px;
  }
  .pro-nav > .pro-nav-links1 {
    position: absolute;
    display: block;
    width: 103%;
    /* background-image: url(../assets/images/10.jpg); */
    height: 0px;
    transition: all 0.2ms ease-in;
    overflow-y: hidden;
    top: 80px;
    left: 10px;
  }
  .pro-nav > .pro-nav-links2 {
    position: absolute;
    display: block;
    width: 103%;
    /* background-image: url(../assets/images/10.jpg); */
    height: 0px;
    transition: all 0.2ms ease-in;
    overflow-y: hidden;
    top: 80px;
    left: 10px;
  }
  .pro-nav > .pro-nav-links > a {
    display: block;
    width: 100%;
  }
  .pro-nav > .pro-nav-links1 > a {
    display: block;
    width: 100%;
  }
  .pro-nav > .pro-nav-links2 > a {
    display: block;
    width: 100%;
  }
  .pro-nav > #pro-nav-check:not(:checked) ~ .pro-nav-links {
    height: 0px;
    z-index: +1;
  }
  .pro-nav > #pro-nav-check:not(:checked) ~ .pro-nav-links1 {
    height: 0px;
    z-index: +1;
  }
  .pro-nav > #pro-nav-check:checked ~ .pro-nav-links {
    height: 300px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: +1;
    top: 82px;
    margin-left: -1%;
  }
  .pro-nav > #pro-nav-check:checked ~ .pro-nav-links1 {
    height: 300px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: +1;
    top: 82px;
  }
  .pro-side {
    padding-left: 4%;
  }
}
.parop-home-boxx {
  display: flex;
  justify-content: end;
  color: #fff;
  flex-direction: row;
}
.parop-home-boxx1 {
  display: flex;
  justify-content: end;
  color: #fff;
  flex-direction: row;
}
.parop-home-boxx a {
  color: #000;
  text-decoration: none;
}
.parop-home-boxx1 a {
  color: #000;
  text-decoration: none;
}
.parop-home-boxx .text-parop-menu {
  color: #1da1f2;
}
.parop-home-boxx1 .text-parop-menu {
  color: #1da1f2;
}

.parop-home-boxx {
  display: flex;
  justify-content: end;
  color: #fff;
  flex-direction: row;
}
.parop-home-boxx1 {
  display: flex;
  justify-content: end;
  color: #fff;
  flex-direction: row;
}
.parop-home-boxx .parop-menu {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 17px;
  margin: 4% 0% 0% 4%;
  white-space: nowrap;
}
.parop-home-boxx1 .parop-menu {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 17px;
  margin: 4% 0% 0% 4%;
  white-space: nowrap;
}
.parop-home-boxx a {
  color: #000;
  text-decoration: none;
}
.parop-home-boxx1 a {
  color: #000;
  text-decoration: none;
}
.parop-home-boxx .text-parop-menu {
  color: #1da1f2;
}
.parop-home-boxx1 .text-parop-menu {
  color: #1da1f2;
}
.parop-home-boxx li ul {
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  display: none;
  padding: 1% 0;
  border: 1px solid #1da1f2;
  height: 130px;
  width: 150px;
}
.parop-home-boxx1 li ul {
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  display: none;
  padding: 1% 0% 1% 0%;
  border: 1px solid #1da1f2;
  /* height: 130px; */
  width: 150px;
}
.hower-text {
  font-size: 11px;
  white-space: normal;
  padding-top: 1%;
  padding-left: 3%;
  color: #1cd4bc;
}
.parop-menu .dropdown .hower-text:hover {
  color: #000;
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  position: absolute;
  right: -4%;
  z-index: +1;
}

.pro-home-nav {
  height: 50px;
  width: 98%;
  background-color: transparent;
  position: relative;
}

.pro-home-nav > .pro-home-nav-header {
  display: inline;
}

.pro-home-nav > .pro-home-nav-header > .pro-home-nav-title {
  display: inline-block;
  font-size: 22px;
  color: #000;
  padding: 0px 10px 10px 10px;
}

.pro-home-nav > .pro-home-nav-btn {
  display: none;
}

.pro-home-nav > .pro-home-nav-links {
  display: inline;
  float: right;
  font-size: 18px;
  width: 40%;
}

.pro-home-nav > .pro-home-nav-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #000;
}
.pro-home-nav > #pro-home-nav-check {
  display: none;
}

/* Tamil */

.pro-home-nav-links .new-tamil-design:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  color: #fff;
}
.pro-home-nav-links .new-tamil-design {
  position: absolute;
  left: 22%;
  top: 20%;
  color: #fff;
  border-radius: 15px;
  padding: 1%;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1vw;
  background-color: rgb(102, 71, 18);
}

.parop-home-tamil-boxx {
  display: flex;
  justify-content: end;
  color: #fff;
  flex-direction: row;
  position: absolute;
  width: 54%;
  left: 48%;
  top: 53%;
}
.parop-home-tamil-boxx1 {
  display: flex;
  justify-content: end;
  color: #fff;
  flex-direction: row;
  position: absolute;
  width: 54%;
  left: 48%;
  top: 53%;
}
.parop-home-tamil-boxx .parop-menu {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1vw;
  white-space: nowrap;
  margin-left: 2%;
}
.parop-home-tamil-boxx1 .parop-menu {
  font-family: cuyabra;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1vw;
  white-space: nowrap;
  margin-left: 2%;
}

.parop-home-tamil-boxx a {
  color: #000;
  text-decoration: none;
}
.parop-home-tamil-boxx1 a {
  color: #000;
  text-decoration: none;
}
.parop-home-tamil-boxx .text-parop-menu {
  color: #1da1f2;
}
.parop-home-tamil-boxx1 .text-parop-menu {
  color: #1da1f2;
}
.parop-home-tamil-boxx li ul {
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  display: none;
  padding: 1% 0% 1% 0%;
  border: 1px solid #1da1f2;
  height: 150px;
  width: 150px;
}
.parop-home-tamil-boxx1 li ul {
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  display: none;
  padding: 1% 0% 1% 0%;
  border: 1px solid #1da1f2;
  height: 145px;
  width: 150px;
}

.pro-home-nav > .pro-home-tamil-nav-links {
  display: inline;
  float: right;
  font-size: 18px;
  width: 60%;
}
.pro-home-nav > .pro-home-tamil-nav-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #000;
}

@media (max-width: 1100px) {
  .parop-home-boxx {
    display: flex;
    justify-content: end;
    color: #fff;
    flex-direction: column;
  }
  .parop-home-boxx1 {
    display: flex;
    justify-content: end;
    color: #fff;
    flex-direction: column;
  }
  .parop-home-boxx .parop-menu {
    font-family: cuyabra;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    /* margin: 3% 0% 0% -4%; */
    margin-top: calc(100% - 97%);
    margin-left: calc(100% - 100%);
  }
  .parop-home-boxx1 .parop-menu {
    font-family: cuyabra;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    /* margin: 3% 0% 0% -4%; */
    margin-top: calc(100% - 97%);
    margin-left: calc(100% - 100%);
  }
  .pro-home-nav-links .new-design {
    position: relative;
    left: 7%;
    color: #fff;
    text-transform: uppercase;
    background-color: rgb(102, 71, 18);
    font-size: 14px;
    padding: 3%;
    margin-top: calc(100% - 90%);
    padding-left: 3%;
    padding-right: 3%;
  }

  .pro-home-nav > .pro-home-nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .pro-home-navv > .pro-home-nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .pro-home-nav > .pro-home-nav-btn > label > .pro-naav > span {
    display: block;
    width: 25px;
    height: 8px;
    border-top: 3px solid #fff;
    cursor: pointer;
  }

  .pro-home-nav > .pro-home-nav-btn > label > .pro-naav:hover {
    background-color: #664712;
  }
  .pro-home-nav > .pro-home-nav-btn > label > .pro-naav {
    border: 1px solid #664712;
    width: 40px;
    height: 40px;
    padding: 20%;
    border-radius: 5px;
    cursor: pointer;
  }

  .pro-home-nav > .pro-home-nav-links {
    position: absolute;
    width: 110%;
    display: block;
    /* background-image: url(../assets/images/10.jpg); */
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 80px;
    left: 10px;
  }
  .pro-home-nav > .pro-home-nav-links > a {
    display: block;
    width: 100%;
  }
  .pro-home-nav > #pro-home-nav-check:not(:checked) ~ .pro-home-nav-links {
    height: 0px;
    display: none;
  }
  .pro-home-nav > #pro-home-nav-check:checked ~ .pro-home-nav-links {
    position: absolute;
    height: 500px;
    width: 100%;
    margin-left: -5%;
    overflow-y: hidden;
    overflow-x: hidden;
    top: 55px;
  }
  .Tiptop {
    margin-top: 70%;
    transition: 0.01ms;
  }
  .home-Tiptop {
    margin-top: calc(100% - 25%);
    transition: 0.01ms;
  }
  .pro-home-nav {
    height: 50px;
    width: 98%;
    margin-top: calc(100% - 93%);
    background-color: transparent;
    position: relative;
  }
  ul li:hover > ul,
  ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    position: absolute;
    left: 10%;
    z-index: +1;
  }
  .pro-top {
    padding-top: calc(100% - 99%);
  }
  .parop-notify {
    padding-top: 6.5%;
  }

  /* tamil */

.parop-home-tamil-boxx{
  display: flex;
  justify-content: end;
  color: #fff;
  flex-direction: column;
  position: unset;
}
.parop-home-tamil-boxx1{
  display: flex;
  justify-content: end;
  color: #fff;
  flex-direction: column;
  position: unset;
}
.parop-home-tamil-boxx .parop-menu{
  font-family:cuyabra ;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  /* margin: 3% 0% 0% -4%; */
  margin-top: calc(100% - 97%);
  margin-left: calc(100% - 100%);

}
.parop-home-tamil-boxx1 .parop-menu{
  font-family:cuyabra ;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  /* margin: 3% 0% 0% -4%; */
  margin-top: calc(100% - 97%);
  margin-left: calc(100% - 100%);

}
.pro-home-nav-links .new-tamil-design {
  position: relative;
  /* top: calc(100% - 90%); */
  top:3%;
  left: 7%;
  color: #fff;
  text-transform: uppercase;
  background-color: rgb(102, 71, 18);
  font-size: 14px;
  padding: 3%;
  margin-top: calc(100% - 90%);
  white-space: nowrap;
}
}
.drop-grid {
  width: 100%;
  margin-left: 0%;
}
#required80G1 {
  display: none;
}
.new__btn {
  display: none;
}
.new__btn2 {
  display: block;
}
@media (max-width: 900px) {
  .new__btn {
    display: block;
  }
  #required80G1 {
    display: block;
  }
}
@media (max-width: 400px) {
  .parop-boxx-top {
    margin-top: 7%;
  }
  /* .pro-home-nav-links, .parop-boxx-top
  {
    margin-top: 7%;
    margin-left: -5%;
  } */
}
.arrow__btn {
  font-size: 10vw;
}
.causefor-container {
  justify-content: center;
  width: 100%;
}
.noti-inv-button {
  width: 10%;
  position: sticky;
  top: 50%;
  left: 88%;
  white-space: nowrap;
}
.group-container {
  margin: 0% 3%;
}
.create-container {
  width: 95%;
  margin-left: 2%;
}
.create-container2 {
  justify-content: center;
}
.changepass-container {
  justify-content: center;
  width: 75%;
  position: absolute;
  left: 13%;
}
.changepass-container2 {
  text-align: center;
  justify-content: center;
}
.search_input {
  width: 23%;
  position: relative;
  left: 75%;
  /* position: absolute;
  right:5%;
  top:2%; */
  height: 40px;
  font-size: 13px;
  font-weight: normal;
  box-sizing: border-box;
  transition: 0.5s;
  outline: none;
}
.search_input:hover {
  border: 1px solid #00aeef;
}
.search_input:focus {
  border: 1px solid #00aeef;
}
@media (max-width: 1200px) {
  .upload-mu {
    margin-bottom: 3%;
  }
}
@media (max-width: 700px) {
  .search_input {
    width: calc(94% - 50%);
    position: relative;
    left: calc(100% - 40%);
    top: 6px;
    height: 35px;
    font-size: 13px;
    font-weight: normal;
    box-sizing: border-box;
    transition: 0.5s;
    outline: none;
  }
}
@media (max-width: 440px) {
  .search_input {
    width: calc(130% - 50%);
    position: relative;
    left: calc(64% - 40%);
    top: 6px;
    height: 35px;
    font-size: 13px;
    font-weight: normal;
    box-sizing: border-box;
    transition: 0.5s;
    outline: none;
  }
}
@media (max-width: 330px) {
  .search_input {
    width: calc(235% - 100%);
    position: relative;
    left: calc(79% - 40%);
    top: 6px;
    height: 35px;
    font-size: 13px;
    font-weight: normal;
    box-sizing: border-box;
    transition: 0.5s;
    outline: none;
  }
}
@media (max-width: 310px) {
  .search_input {
    width: calc(211% - 100%);
    position: relative;
    left: calc(150% - 40%);
    top: 6px;
    height: 35px;
    font-size: 13px;
    font-weight: normal;
    box-sizing: border-box;
    transition: 0.5s;
    outline: none;
  }
}
@media (max-width: 300px) {
  .search_input {
    width: calc(293% - 100%);
    position: relative;
    left: calc(100% - 40%);
    top: 6px;
    height: 35px;
    font-size: 13px;
    font-weight: normal;
    box-sizing: border-box;
    transition: 0.5s;
    outline: none;
  }
}
@media (max-width: 270px) {
  .search_input {
    width: calc(700% - 300%);
    position: relative;
    left: calc(100% - 40%);
    top: 6px;
    height: 35px;
    font-size: 13px;
    font-weight: normal;
    box-sizing: border-box;
    transition: 0.5s;
    outline: none;
  }
}
@media (max-width: 250px) {
  .search_input {
    width: calc(950% - 100%);
    position: relative;
    left: calc(100% - 40%);
    top: 6px;
    height: 35px;
    font-size: 13px;
    font-weight: normal;
    box-sizing: border-box;
    transition: 0.5s;
    outline: none;
  }
}

@media (max-width: 230px) {
  .search_input {
    width: calc(900% - 100%);
    position: relative;
    left: calc(100% - 40%);
    top: 6px;
    height: 35px;
    font-size: 13px;
    font-weight: normal;
    box-sizing: border-box;
    transition: 0.5s;
    outline: none;
  }
}
@media (max-width: 200px) {
  .search_input {
    width: calc(855% - 100%);
    position: relative;
    left: calc(100% - 40%);
    top: 6px;
    height: 35px;
    font-size: 13px;
    font-weight: normal;
    box-sizing: border-box;
    transition: 0.5s;
    outline: none;
  }
}

/* @media (max-width:1100px) {
    .search_input{
      width: calc(100% - 60%);
      position: relative;
      left:calc(100% - 40%);
      top:2%;
      height: 35px;
      font-size: 13px;
      font-weight: normal;
      box-sizing: border-box;
      transition: 0.5s;
      outline: none;
    }
  } */
.emp_place_mu {
  display: flex;
  flex-direction: row;
}

.resetp-container {
  justify-content: center;
  width: 75%;
  position: absolute;
  left: 13%;
  padding: 0% 10%;
}
.resetp-container2 {
  padding: 0% 10%;
  text-align: center;
  justify-content: center;
}
::-ms-reveal {
  display: none;
}
/*  updategroup image  */
.pic-container {
  cursor: pointer;
  overflow: hidden;
}

.pic {
  width: 100%;
  height: 100%;
  display: block;
}

.pic-container.pic-xs {
  width: 50px;
  height: 50px;
}

.pic-container.pic-small {
  width: 70px;
  height: 70px;
}

.pic-container.pic-medium {
  width: 150px;
  height: 150px;
}

.pic-container.pic-large {
  width: 250px;
  height: 250px;
}

.pic-container.pic-xl {
  width: 350px;
  height: 350px;
}

.pic-container.pic-circle {
  border-radius: 50%;
}

.pic-overlay {
  background-color: #ccc;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic-container:hover .pic-overlay {
  top: -100%;
}

.pic-overlay a {
  display: block;
  padding: 10px;
}

.pic-xs .pic-overlay a {
  padding: 5px;
}

.pic-overlay .fa-window-close-o {
  color: red;
}

.pic-overlay .fa-pencil-square-o {
  color: blue;
}

.pic-medium .pic-overlay a i {
  font-size: 20px;
}

.pic-large .pic-overlay a i {
  font-size: 35px;
}

.pic-xl .pic-overlay a i {
  font-size: 45px;
}
/* popup header background   */
#Headerback {
  background: linear-gradient(rgb(2, 170, 176) 0%, rgb(0, 205, 172) 100%);
  border-radius: 5px;
}
.grp-edit-img {
  width: 30px;

  height: 30px;

  border-radius: 50%;
}
.grp-edit-img {
  width: 30px;

  height: 30px;

  border-radius: 50%;
}
.multiimages {
  overflow-y: scroll;
  max-height: 100px;
  -webkit-overflow-scrolling: touch;
}

/* Custom scrollbar styles */
.multiimages::-webkit-scrollbar {
  width: 6px;
}

.multiimages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.multiimages::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.multiimages::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* .grp-edit-img2{

  width: 30px;

  height: 30px;

  border-radius: 50%;

  position: absolute;

}

.group-image{

  width: 30px;

  height: 30px;

  border-radius: 50%;

 position: relative;

} */

.grp-edit-img2 {
  width: 30px;

  border-radius: 1px solid grey;

  height: 30px;

  border-radius: 50%;
}

.grp-edit-img3 {
  width: 30px;

  border-radius: 1px solid grey;

  height: 42px;

  border-radius: 50%;
}
.grp-edit-img4 {  

  border-radius: 1px solid grey;

  height: 30px;

  border-radius: 50%;
}


.group-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  margin: -3px 0px 0px -29px;
}

.tag-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tag-image {
  width: 3rem;  
  height: 3rem; 
  border-radius: 50%;
  margin-left: -11%;
  padding-right: 0.3rem;
  position: relative;
}

.navigation-label {
  cursor: pointer;
}
.navigation-label:hover {
  color: white; 
}

.highlight {
  color: grey
}



.topcontainer {
  display: flex;
  align-items: center;
  padding: 10px;
  flex-wrap: wrap;
  width: 102%;
  margin-left: -4px;
}

.categories,
.sort,
.searchs {
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  box-sizing: border-box;
  width: 50%;
}

.categories,
.sort {
  flex: 1 1 200px;
  max-width: 324px;
}

.searchs {
  flex: 1 1 100px;
  max-width: 200px;
  margin-left: auto;
}

@media (max-width: 768px) {
  .categories,
  .sort,
  .searchs {
    flex: 1 1 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.modalconfirmbtn {
  margin: 10% 18%;

  margin-left: 20%;

  justify-content: center;

  display: flex;
}

.modaltext {
  text-align: center;
}

.button-confirm {
  width: 70px;

  color: white;

  background-color: rgb(102, 71, 18);

  margin-right: 30px;
}
.table.no-border tr td,
.table.no-border tr th {
  border-width: 0;
}
.card:hover {
  /* / background-color: rgb(196, 13, 13); / */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.cards {
  width: 40%;
  height: auto;

  margin: auto;
}
@media (max-width: 900px) {
  .cards {
    width: 100%;
    margin-bottom: 4%;
  }
}

/* 
#pulse {

  font-size: 14px;
  background-color: #D0FF76;
  color: #333;
    opacity: 0.8;
  cursor: grab;
}

#pulse:hover {
  
  border-radius: 100px;
 
  opacity: 1;
 
  border-color: black;
  
}

.grow-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.grow-on-hover:hover, .grow-on-hover:focus, .grow-on-hover:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */
.table.no-border tr td,
.table.no-border tr th {
  border-width: 0;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.cards {
  width: 40%;
  height: auto;

  margin: auto;
}
@media (max-width: 900px) {
  .cards {
    width: 100%;
    margin-bottom: 4%;
  }
}
#pulse {
  font-size: 14px;
  background-color: #d0ff76;
  color: #333;
  opacity: 0.8;
  cursor: grab;
}
#tsum-tabs h1 {
  padding: 50px 0;
  font-weight: 400;
  text-align: center;
}

#tsum-tabs p {
  margin: 0 0 20px;
  line-height: 1.5;
  /* / padding: 0 0 0 15px; / */
}
#tsum-tabs main {
  min-width: 320px;
  max-width: 800px;
  padding: 0px;
  margin: 0 auto;
  text-align: center;
  /* / background: #fff; /
  / background-image: url(../assets/images/10.jpg); / */
}

#tsum-tabs section {
  display: none;
  padding: 20px 0 0;
  /* / border-top: 1px solid #ddd; / */
}

#tsum-tabs input {
  display: none;
  /* / border-bottom: 2px solid #d0d0d0; / */
}

#tsum-tabs label {
  /* display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: rgb(12, 10, 10);
  border: 1px solid transparent; */
  background: #edf0f2;
  background: -webkit-linear-gradient(180deg, rgb(53, 192, 197), #edf0f2);
  background: -moz-linear-gradient(180deg, white, #edf0f2);
  background: -ms-linear-gradient(180deg, white, #edf0f2);
  background: -o-linear-gradient(180deg, white, #edf0f2);
  background: linear-gradient(180deg, #edf0f2, white);
  padding: 10px 30px 10px 20px;
  border-radius: 15px 0 16px 0;
  border: 1px solid #d0d0d0;
  border-left: 3px solid #d0d0d0;
  border-bottom: none;
  margin: 0 0 0 -10px;
  text-shadow: 1px 1px 0 white;
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 1);
}
.tsum-tabs:last-of-type {
  border-radius: 15px 0 0 0;
  padding: 10px 20px;
}
#tsum-tabs label:before {
  font-family: fontawesome;
  font-weight: normal;
  margin-right: 10px;
}

#tsum-tabs label[for*="1"]:before {
  content: "\f0c0";
}
#tsum-tabs label[for*="2"]:before {
  content: "\f234";
}

#tsum-tabs label:hover {
  background: #ceedbf;
  background: -webkit-linear-gradient(180deg, #fcdce2, white 70%);
  background: -moz-linear-gradient(180deg, #fcdce2, white 70%);
  background: -ms-linear-gradient(180deg, #fcdce2, white 70%);
  background: -o-linear-gradient(180deg, #fcdce2, white 70%);
  background: linear-gradient(180deg, #fcdce2, white 70%);
  cursor: pointer;
  box-shadow: inset 0 2px 0 0 #c7f0d2;
  /* / background-color: teal; / */
}

#tsum-tabs input:checked + label {
  /* color: rgba(12, 10, 10, 0.925);
  border: 1px solid #ddd; 
 border-top: 2px solid rgb(66, 32, 3);
  border-bottom: 1px solid #ddd;
 
  background: #fff;
  color: #000;
  border-top: 3px solid #32557f; */
  background: #ceedbf;
  background: -webkit-linear-gradient(180deg, #fcdce2, white 70%);
  background: -moz-linear-gradient(180deg, #fcdce2, white 70%);
  background: -ms-linear-gradient(180deg, #fcdce2, white 70%);
  background: -o-linear-gradient(180deg, #fcdce2, white 70%);
  background: linear-gradient(180deg, #c7f0d2, #c7f0d2);
  cursor: pointer;
  box-shadow: inset 0 2px 0 0 #c7f0d2;
}

#tsum-tabs #tab1:checked ~ #content1,
#tsum-tabs #tab2:checked ~ #content2 {
  display: block;
}

@media screen and (max-width: 650px) {
  #tsum-tabs label {
    font-size: 0;
  }
  #tsum-tabs label:before {
    margin: 0;
    font-size: 18px;
  }
}

@media screen and (max-width: 400px) {
  #tsum-tabs label {
    padding: 15px;
  }
}
#maintab {
  border-radius: 20px;
}

#Iframe-Master-CC-and-Rs {
  max-width: 649px;
  max-height: 343px;
  overflow: hidden;
}

.responsive-wrapper {
  height: 0;
}

.responsive-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;
  border: none;
}

.responsive-wrapper-wxh-572x612 {
  padding-bottom: 53%;
}
.VIpgJd-ZVi9od-ORHb-OEVmcd {
  position: absolute !important;
  z-index: 1 !important;
}

.center-block-horiz {
  margin-left: auto !important;
  margin-right: auto !important;
}
#Regclaim {
  overflow: hidden;
  z-index: 2;
  position: relative;
  width: 100px;
  height: 36px;

  text-transform: uppercase;
  font-size: 15px;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 20px;
  background: rgb(102, 71, 18);
  color: white;
  text-transform: uppercase;
  border: 2px solid rgb(102, 71, 18);
  border-radius: 30px;
}

.post-bottom {
  display: flex;
  flex-wrap: wrap; /* Ensures responsiveness */
  justify-content: space-between; /* Adjust as needed */
  gap: 15px; /* Adjust based on your layout needs */
}
@media (max-width: 576px) {
  .post-bottom {
    gap: 10px; /* Adjust based on your layout needs */
  }
}

/* Default styling for Tag, Like, and Comment elements */
@media (min-width:344px) and (max-width:405px)
{
  .post-bottom {
     margin-right: -2pc;
  }
}

@media (min-width:315px) and (max-width:344px)
{
  .post-bottom {
    margin-right: -2pc;
  }
}

/* Media query for screen width 384 pixels or larger */
@media (max-width: 412px) and (min-width:315px){
  .tag-like-comment {
    flex-direction: row;
  }
  #homelike {
    margin-left: -20px;
  }

  .post-bottom {
    font-size: 11.5px;
  }
  #donatesmileb {
    margin-left: 0pc;
  }
  #button {
    margin-left: 0pc;
  }
  .modallyy {
    margin-left: -1pc;
  }
}
#paropakarilogo
{
  width: 26%;
}

@media (min-width:2004px) and (max-width:4000px)
{
  .navbar-new-text
  {
    display: flex;
  }
  #paropakarilogo
  {
    width:2vw;
  }

}
@media (min-width:2004px) and (max-width:2300px)
{
  #tag-like-comment {
    width: 42vw;
    margin-left: -2pc;
  }
 
}
@media (min-width:2300px) and (max-width:3999px)
{
  #uploadplace
  {
    margin-left: -6pc;
  }
  #postedon
  {
    margin-left: -5pc;
  }
  #postedons
  {
    margin-left: -5pc;
  }
  #enddate
  {
    margin-left: -5pc;
  }
  #enddates
  {
    margin-left: -5pc;
  }
  #tag-like-comment {
    width: 38vw;
    margin-left: -2pc;
  }
 
 
}
@media (width:4000px) 
{
  #imagesss
  {
    margin-left: -6pc;
  }
}

@media (min-width:4000px) and (max-width:9000px)
{
  .navbar-new-text
  {
    display: flex;
  }

 
  #paropakarilogo
  {
    width:2vw;
  }
  #uploadplace
  {
    margin-left: -6pc;
  }
}

@media (min-width:768px) and (max-width:992px) {
  #imagesss
  {
    width: 88vw;
  }
  #imagessss
  {
    width: 88vw;
  }
  #postedon
  {
    display: flex;
    left: 15rem;
    width: 38%;
  }
  #enddate
  {
    display: flex;
    left: 15rem;
    width: 38%;
    top: 1pc;
  }
  #postedons
  {
    display: flex;
    left: 15rem;
    width: 38%;
  }
  #enddates
  {
    display: flex;
    left: 15rem;
    width: 38%;
    top: 1pc;
  }
}


@media (min-width:992px) and (max-width:1201px) {
#imagesss
{
  width: 116vw;
}

#notifysidebar
{
  right: 1pc;
}
#postedon
{
  left: 17pc;
  display: flex;
}
#postedons
{
  left: 17pc;
  display: flex;
}
#enddate
{
left: 17pc;
}
#enddates
{
left: 17pc;
}
.post-bottom
{
  margin-right: 1pc;
  width: 100%;
}
 
}

@media (min-width:768px) and (max-width:808px)

{
  #imagessss
  {
    width: 85vw;
  }
 
}

@media (min-width:992px) and (max-width:1200px)

{
  #imagessss
  {
    width: 94vw;
  }
  /* #myactivitybar
  {
    width: 1%;
  } */
 
}
#editdlt
  {
    display: flex;
    margin-top: 1pc;
  }

@media (min-width:768px) and (max-width:1080px)
{
  #editdlt
  {
    display: -webkit-box;
    left: 3pc;
  }
}

@media (min-width:250px) and (max-width:600px)

{

  #arrowspace
  {
    margin-top: 1pc;
  }
}


 
#buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

#buttonContainer ul {
  padding: 0;
  margin: 0;
}

#buttonContainer li {
  display: inline-block;
}

#viewDetailsBtn, #donateBtn, #achievedBtn, #defaultBtn {
  width: auto;
  padding: 10px 20px;
}

@media only screen and (max-width: 768px) {
  #viewDetailsBtn, #donateBtn, #achievedBtn, #defaultBtn {
    width: 100%;
    margin-bottom: 10px;
  }
}
/* .flex-containers {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  text-align: center;
  width: 60%;
}

.flex-item-lefts {
  background-color: red;
  padding: 10px;
  flex: 29%;
  color: black;
  place-content: end;
  display: inherit;
}

.flex-item-rights {
  background-color: red;
  padding: 10px;
  flex: 29%;
  color: black;
}

@media (max-width: 800px) {
  .flex-containers {
    flex-direction: column;
  }
}

.dsbut
{
  width: 60%;
  display: inherit;
  place-content: center;
}
.donatesmile-btn
{
  background-color: yellow;
}
.viewdetails-btn
{
  background-color: blue;
}

.achibut
{
  width: 100%;
} */

#PostButton
{
  bottom: 4pc;
}

@media (min-width:250px) and (max-width:500px)
{
  #PostButton
  {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  #buttsdiv
  {
    width: 150px;
  }
}

@media (min-width:500px) and (max-width:768px)
{
  #PostButton
  {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }
 
}

@media (min-width:768px) and (max-width:992px)
{
  #PostButton
  {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }
}